export const baseSurvey = {
    "title": "Ford Experience Survey",
    "logo": "https://cdn.latitudewebservices.com/logos/Ford_Signature_White.png",
    "logoWidth": "auto",
    "logoHeight": "3em",
    "logoPosition": "left",
    "completedHtml": "<h3>Thank you for your participation.</h3>",
    "focusFirstQuestionAutomatic": false,
    // "cookieName": "1e985076-2794-463a",
    "showQuestionNumbers": "off",
    "autoGrowComment": true,
    "startSurveyText": "Begin",
    "completeText": "Finish",
    "widthMode": "responsive",
    "fitToContainer": true,
    "headerView": "advanced",
    "checkErrorsMode": "onValueChanged",
    "textUpdateMode": "onBlur",
    "pages": [
        {
            "name": "basicSurvey",
            "elements": [
                {
                    "type": "panel",
                    "name": "nameAddress",
                    "elements": [
                        {
                            "type": "text",
                            "name": "first_name",
                            "title": "First Name",
                            "isRequired": true,
                            "autocomplete": "given-name"
                        },
                        {
                            "type": "text",
                            "name": "last_name",
                            "startWithNewLine": false,
                            "title": "Last Name",
                            "isRequired": true,
                            "autocomplete": "family-name"
                        },
                        {
                            "type": "text",
                            "name": "address1",
                            "title": "Street Address",
                            "isRequired": true,
                            "autocomplete": "address-line1",
                            "addressAutocompleteConfig": {
                                "addressPartMap": {
                                    "address1": "address1",
                                    "address2": "address2",
                                    "city": "city",
                                    "state": "state",
                                    "zip": "zip"
                                }
                            },
                        },
                        {
                            "type": "text",
                            "name": "address2",
                            "startWithNewLine": false,
                            "title": "Apt/Suite/Other",
                            "autocomplete": "address-line2"
                        },
                        {
                            "type": "text",
                            "name": "city",
                            "title": "City",
                            "isRequired": true
                        },
                        {
                            "type": "text",
                            "name": "state",
                            "isRequired": true,
                            "startWithNewLine": false,
                            "title": "State"
                        },
                        {
                            "type": "text",
                            "name": "zip",
                            "isRequired": true,
                            "valueName": "zip_code",
                            "maxLength": "6",
                            "startWithNewLine": false,
                            "title": "Zip Code",
                            "autocomplete": "postal-code"

                        },
                        {
                            "type": "text",
                            "name": "email",
                            "title": "Email Address",
                            "requiredIf": "{phone} empty",
                            "requiredErrorText": "Either Email Or Phone Is Required",
                            "inputType": "email",
                            "autocomplete": "email",
                            "validators": [
                                {
                                    "type": "email"
                                },
                                {
                                    "type": "expression",
                                    "text": "Please enter a valid e-mail address.",
                                    "expression": "validateEmail({email})"
                                }
                            ],
                        },
                        {
                            "type": "text",
                            "name": "phone",
                            "startWithNewLine": false,
                            "title": "Mobile Number",
                            "description": "Standard message and data rates may apply",
                            "descriptionLocation": "underInput",
                            "requiredIf": "{email} empty",
                            "requiredErrorText": "Either Email Or Phone Is Required",
                            "inputType": "tel",
                            "autocomplete": "tel",
                            "inputMask": "phone",
                            "inputFormat": "999-999-9999",
                        },
                        {
                            "type": "html",
                            "name": "privacy",
                            "html": "<p style=\"font-size:0.7em;line-height:1.5em;\">Ford Motor Company respects your privacy and treats your personal information with care.<br/><a href=\"https://www.ford.com/help/privacy/\" target=\"_blank\">Click here to read Ford Motor Company's privacy policy.</a></p>"
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "medium",
                            "name": "accepts_sms",
                            "title": "Please send me vehicle information via text message.",
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": 1,
                                    "text": "Yes"
                                },
                                {
                                    "value": 0,
                                    "text": "No"
                                },
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "large",
                            "name": "how_likely_recommend",
                            "title": "How likely are you to recommend Ford to a friend, relative or colleague?",
                            "isRequired": true,
                            "choices": [
                                "Definitely Will NOT Recommend",
                                "Probably Will NOT Recommend",
                                "Maybe Will / Maybe Will Not Recommend",
                                "Probably Will Recommend",
                                "Definitely Will Recommend",
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "medium",
                            "name": "email_opt_in",
                            "title": "Please email me communications including product information, offers, and incentives from Ford Motor Company and the local dealer.",
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": 1,
                                    "text": "Yes"
                                },
                                {
                                    "value": 0,
                                    "text": "No"
                                },
                            ]
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "baseQuestions",
                    "elements": [
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "medium",
                            "name": "gender",
                            "title": "Gender",
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": "male",
                                    "text": "Male"
                                },
                                {
                                    "value": "female",
                                    "text": "Female"
                                },
                                {
                                    "value": "noAnswer",
                                    "text": "Prefer Not to Answer"
                                },
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "medium",
                            "name": "age_bracket",
                            "title": "May I ask your age?",
                            "isRequired": true,
                            "choices": [
                                "18 to 24",
                                "25 to 29",
                                "30 to 34",
                                "35 to 39",
                                "40 to 44",
                                "45 to 49",
                                "50 to 59",
                                "Over 60",
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "medium",
                            "name": "current_owner",
                            "title": "Are you currently a Ford owner?",
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": 1,
                                    "text": "Yes"
                                },
                                {
                                    "value": 0,
                                    "text": "No"
                                },
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "large",
                            "name": "ford_model",
                            "visibleIf": "{current_owner} = 1",
                            "title": "Which model of Ford do you drive most often?",
                            "isRequired": true,
                            "choices": [
                                "Bronco",
                                "Bronco Sport",
                                "Edge",
                                "Escape",
                                "Expedition",
                                "Explorer",
                                "F-150",
                                "F-150 Lightning",
                                "Ford GT",
                                "Maverick",
                                "Mustang",
                                "Mustang Mach-E",
                                "Ranger",
                                "Super Duty",
                                "Transit",
                                "Other"
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "large",
                            "name": "brand_for_me",
                            "title": "How much do you agree or disagree with the statement \"Ford is a brand for me\"?",
                            "isRequired": true,
                            "choices": [
                                "Completely Disagree",
                                "Somewhat Disagree",
                                "Neither Agree or Disagree",
                                "Somewhat Agree",
                                "Completely Agree",
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "medium",
                            "name": "how_likely_acquire",
                            "title": "How do you plan to acquire your next vehicle?",
                            "isRequired": true,
                            "choices": [
                                "Purchase",
                                "Lease"
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "large",
                            "name": "how_likely_purchasing",
                            "title": "The next time you are shopping for an automotive vehicle, how likely are you to consider a Ford?",
                            "isRequired": true,
                            "choices": [
                                "Definitely Will NOT Consider",
                                "Probably Will NOT Consider",
                                "Maybe Will / Maybe Will NOT Consider",
                                "Probably Will Consider",
                                "Definitely Will Consider",
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "renderAs": "radiobuttongroup",
                            "buttonSize": "large",
                            "name": "in_market_timing",
                            "title": "When do you plan to acquire your next vehicle?",
                            "isRequired": true,
                            "choices": [
                                "0-30 Days",
                                "1-3 Months",
                                "4-6 Months",
                                "7+ Months",
                                "No Definite Plans"
                            ]
                        },
                        {
                            "type": "checkbox",
                            "name": "voi",
                            "renderAs": "voi",
                            "title": "I am interested in learning more about the following Ford vehicles.",
                            "description": "You may select up to three models.",
                            "maxSelectedChoices": 3,
                            "choicesByUrl": {
                                "url": "https://cdn.latitudewebservices.com/vehicles/ford.json",
                                "valueName": "id",
                                "titleName": "name",
                                "image": "image"
                            }
                        }
                    ]
                },
            ]
        }
    ],
}

export const incentiveThanks = "<div><h3>Thank You for participating in this Ford event</h3><p>Please show this QR code to one of the attendants to show you completed the survey</p><br/><br/><div style='top:0;left:0;position:relative;'><img alt='Ford' style='height: 40px; position: absolute; left: 50%; top: 50%; z-index: 2; transform: translate(-50%, -50%);' src='https://cdn.latitudewebservices.com/expanse_marketing/2024/ford/ford_logo.png'><img alt='{device_survey_guid}' src='https://pfg.latitudewebservices.com/microsite/v1/events/qr?str={device_survey_guid}' style='position: relative; height: 200px; width: 200px;' /><div></div>";
